import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { AngularFirestore } from 'angularfire2/firestore';

declare var paypal;

@Component({
  selector: 'app-payme',
  templateUrl: './payme.component.html',
  styleUrls: ['./payme.component.css']
})
export class PaymeComponent implements OnInit {
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;

  constructor(public db: AngularFirestore) {
  }

  product = {
    title: 'Kartenspiel',
    price: 20.00,
  };

  paidFor = false;
  payer = null;
  quantity = 1;
  shipping = {
    fee: 4.99,
    description: 'Standard DHL Versand',
  };

  onOptionsSelected(value: string) {
    // tslint:disable-next-line:radix
    this.quantity = parseInt(value);
  }

  ngOnInit() {
    paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: this.product.title,
                amount: {
                  currency_code: 'EUR',
                  value: (this.product.price * this.quantity + this.shipping.fee).toFixed(2)
                }
              },
            ]
          });
        },
        onApprove: async (data, actions) => {
          let order = await actions.order.capture();
          this.paidFor = true;
          this.payer = order.payer.name.given_name;
          order = {
            ...order,
            quantity: this.quantity,
            shipping_status: 'open'
          };

          this.db.collection('/orders').add(order);
        },
        onError: err => {
          console.log(err);
        }
      })
      .render(this.paypalElement.nativeElement);
  }
}
