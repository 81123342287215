import {Component, OnInit} from '@angular/core';

import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {AngularFirestore, AngularFirestoreCollection} from 'angularfire2/firestore';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  private ordersCollection: AngularFirestoreCollection<any>;
  orders: Observable<any[]>;
  status: {};
  currentStatus: string;

  constructor(private db: AngularFirestore, public auth: AuthService) {
  }

  onOptionsSelected(state: string) {
    this.getOrdersByState(state);
  }

  changeState(state: string, orderId: string) {
    this.db.collection('orders').doc(orderId).update({shipping_status: state});
  }

  getOrdersByState(state: string) {
    this.ordersCollection = this.db.collection('orders', ref => {
      return ref.where('shipping_status', '==', state).orderBy('create_time', 'asc');
    });
    this.orders = this.ordersCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const docId = a.payload.doc.id;
        return { docId, ...data };
      }))
    );
    this.currentStatus = state;
  }

  ngOnInit() {
    this.getOrdersByState('open');

    this.status = {
      open: 'Offen',
      shipped: 'Versandet',
      canceled: 'Abgebrochen',
      returned: 'Retour'
    };
  }

}
